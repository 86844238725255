// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-series-tsx": () => import("./../../src/pages/series.tsx" /* webpackChunkName: "component---src-pages-series-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-templates-post-detail-tsx": () => import("./../../src/templates/post-detail.tsx" /* webpackChunkName: "component---src-templates-post-detail-tsx" */),
  "component---src-templates-post-list-tsx": () => import("./../../src/templates/post-list.tsx" /* webpackChunkName: "component---src-templates-post-list-tsx" */),
  "component---src-templates-series-list-tsx": () => import("./../../src/templates/series-list.tsx" /* webpackChunkName: "component---src-templates-series-list-tsx" */)
}

